@font-face {
    font-family: "Amazon Ember";
    src: url("https://m.media-amazon.com/images/G/01/AUIClients/AmazonUIFont-amazonember_rg-cc7ebaa05a2cd3b02c0929ac0475a44ab30b7efa._V2_.woff2");
}

@font-face {
    font-family: "Amazon Ember";
    src: url("https://m.media-amazon.com/images/G/01/AUIClients/AmazonUIFont-amazonember_rgit-9cc1bb64eb270135f1adf3a4881c2ee5e7c37be5._V2_.woff2");
    font-style: italic;
}

@font-face {
    font-family: "Amazon Ember";
    src: url("https://m.media-amazon.com/images/G/01/AUIClients/AmazonUIFont-amazonember_bd-46b91bda68161c14e554a779643ef4957431987b._V2_.woff2");
    font-weight: 700;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

a {
    outline: 0;
}

button {
    font-family: "Amazon Ember", Arial, Helvetica, sans-serif;
}

body {
    margin: 0;
    font-family: "Amazon Ember", Arial, Helvetica, sans-serif;
}
